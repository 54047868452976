import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabContent c-tabContent--noSpace" }
const _hoisted_2 = { class: "l-grid l-grid--splitV l-grid--gXl" }
const _hoisted_3 = { class: "l-grid__row" }
const _hoisted_4 = { class: "l-grid__item l-grid__item--3col" }
const _hoisted_5 = { class: "l-grid__item l-grid__item--9col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Sidebar, {
            "prop-groups": _ctx.groups,
            "onUpdate:prop-groups": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groups) = $event)),
            "prop-employees": _ctx.employees,
            "onUpdate:prop-employees": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.employees) = $event)),
            onDropDone: _ctx.updateGroupEmployee
          }, null, 8, ["prop-groups", "prop-employees", "onDropDone"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_view, {
            key: _ctx.route.path,
            "prop-groups": _ctx.groups,
            "onUpdate:prop-groups": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.groups) = $event)),
            "prop-employees": _ctx.employees,
            "onUpdate:prop-employees": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employees) = $event)),
            onRefresh: _ctx.init
          }, null, 8, ["prop-groups", "prop-employees", "onRefresh"])
        ])
      ])
    ])
  ]))
}