
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  props: ['propDevice'],
  setup(props) {
    const device = reactive(props.propDevice)
    return {
      device
    }
  }
})
