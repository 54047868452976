
import { computed, defineComponent, inject, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/http'
import { useStore } from 'vuex'
import Fixed from '@/views/components/fixed.vue'
import { employeeForm, notifyInstance } from '@/type'

export default defineComponent({
  emits: ['refresh'],
  props: ['propEmployees', 'propGroups'],
  components: {
    Fixed
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const employee = reactive({
      name: '',
      email: '',
      password: ''
    } as employeeForm)

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const groupId = computed(() => route.query.groupId ?? '')

    function save() {
      axios
        .post(`/employees/${storeId.value}`, {
          groupId: groupId.value,
          employee: employee
        })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      employee.name = ''
      employee.email = ''
      employee.password = ''
    }

    function inputPassword(event: any) {
      let value = event.target.value
      if (value.length > 4) {
        value = value.slice(0, 4)
      }
      employee.password = value
    }

    return {
      employee,
      save,
      cancel,
      inputPassword
    }
  }
})
