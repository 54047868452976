
import Sidebar from './components/sidebar.vue'
import { computed, defineComponent, inject, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '@/http'
import { employee, group, notifyInstance } from '@/type'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Sidebar
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const employees = ref(new Array<employee>())
    const groups = ref(new Array<group>())

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    function init() {
      axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      axios
        .get(`/groups/${storeId.value}`)
        .then((res) => {
          const data = res.data
          groups.value = data.groups
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function updateGroupEmployee() {
      axios
        .patch(`/groups/${storeId.value}`, {
          type: 'groupEmployee',
          groups: groups.value
        })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    init()

    return {
      employees,
      groups,
      route,
      updateGroupEmployee,
      init
    }
  }
})
