
import Collapse from './components/collapse.vue'
import Fixed from '@/views/components/fixed.vue'
import listBox from '@/views/components/listBox.vue'

import { defineComponent, ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import axios from '@/http'
import { category, notifyInstance } from '@/type'
import { useRouter } from 'vue-router'

export default defineComponent({
  emits: ['refresh'],
  props: ['propEmployees', 'propGroups'],
  components: {
    Collapse,
    Fixed,
    listBox
  },
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()

    const categories = ref(new Array<category>())
    const allowUsers = ref()
    const exceptionAcls = ref(new Array<any>())
    const supportSwitchAcls = ref(new Array<any>())

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    function init() {
      axios
        .get(`/permissions/${storeId.value}`)
        .then((res) => {
          allowUsers.value = res.data.allowUsers
          exceptionAcls.value = res.data.exceptionAcls
          supportSwitchAcls.value = res.data.supportSwitchAcls

          Object.assign(
            categories.value,
            res.data.permissions.map((category: any) => {
              const mapAclItems = category.aclItems.map((aclItem: any) => {
                return {
                  id: aclItem.id,
                  title: aclItem.name,
                  isCollapseOpen: false
                }
              })
              return {
                title: category.title,
                aclItems: mapAclItems
              }
            })
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function save() {
      axios
        .patch(`/permissions/${storeId.value}`, {
          allowUsers: allowUsers.value,
          exceptionAcls: exceptionAcls.value
        })
        .then((res) => {
          // refresh sideBar
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    function allowUserName(aclItem: any) {
      const allowUser = allowUsers.value[aclItem.id] ?? []
      const employees = props.propEmployees.filter((employee: any) => {
        return employee.status && allowUser.indexOf(employee.id) !== -1
      })

      if (employees.length <= 0) {
        return ''
      }

      let employeeName = employees
        .map((employee: any) => employee.name)
        .reduce((cur: any, name: any) => {
          return cur + '、' + name
        })
      if (employeeName.length > 20) {
        employeeName = employeeName.slice(0, 20) + '...'
      }
      return employeeName
    }

    function toggleExceptionAcl(aclItemId: string) {
      const index = exceptionAcls.value.indexOf(aclItemId)

      if (index === -1) {
        exceptionAcls.value.push(aclItemId)
      } else {
        exceptionAcls.value.splice(index, 1)
      }
    }

    init()

    return {
      categories,
      allowUserName,
      save,
      cancel,
      allowUsers,
      exceptionAcls,
      toggleExceptionAcl,
      supportSwitchAcls
    }
  }
})
