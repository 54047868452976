
import Fixed from '../../../components/fixed.vue'
import { computed, defineComponent, ref, reactive, inject } from 'vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { notifyInstance } from '@/type'
import store from '@/store'

export default defineComponent({
  components: {
    Fixed
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()

    const storeName = ref('')
    const storeEmail = ref('')
    const isEnable = ref(true)
    const newStoreFrom = reactive({
      storeName,
      storeEmail,
      isEnable
    })

    const allowEmails = computed(() => store.getters.allowEmails)
    const selectEmails = computed(() =>
      allowEmails.value.map((item: any) => {
        return item.email
      })
    )

    function save() {
      axios
        .post('/store', newStoreFrom)
        .then((res) => {
          const storeId = res.data.storeId
          const newToken = res.data.token
          store.dispatch('revokedToken', storeId).then(() => {
            if (newToken !== '') {
              store.dispatch('resetToken', newToken)
            }
            store.dispatch('initSetup').then(() => {
              router.push({
                name: 'authStore'
              })
            })
          })
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      router.push({
        name: 'authStore'
      })
    }

    return {
      storeName,
      storeEmail,
      isEnable,
      selectEmails,
      save,
      cancel
    }
  }
})
