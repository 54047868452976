
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  emits: ['deleteDeviceClick'],
  props: ['propDevice'],
  setup(props, { emit }) {
    const device = reactive(props.propDevice)
    return {
      device,
      emit
    }
  }
})
