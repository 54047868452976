
import DeviceList from '../store/components/deviceList.vue'
import DeviceRecord from '../store/components/deviceRecord.vue'
import StoreList from '../store/components/storeList.vue'
import { defineComponent, computed, ref, inject } from 'vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { device, notifyInstance, modalInstance } from '@/type'
import Fixed from '@/views/components/fixed.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Fixed,
    DeviceList,
    StoreList,
    DeviceRecord
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()

    const allowEmails = computed(() => store.getters.allowEmails)
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const storeName = ref('')
    const storeEmail = ref('')
    const isEnable = ref('')
    const selectEmails = ref(Array<string>())
    const devices = ref(new Array<device>())

    function save() {
      axios
        .patch(`/store/${storeId.value}`, {
          storeName: storeName.value,
          storeEmail: storeEmail.value,
          isEnable: isEnable.value,
          devices: devices.value.map((device: device) => {
            return {
              id: device.id,
              name: device.name,
              isEnable: device.isEnable
            }
          })
        })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗，${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      router.push({
        name: 'refresh'
      })
    }

    function deleteDeviceClick(device: any) {
      const deleteDeviceModal = modal({
        title: device.name,
        content: '刪除設備將無法執行WACA POS APP',
        confirmBtnText: '刪除',
        classType: 'alert',
        confirmBtnClick: () => {
          return new Promise((resolve, reject) => {
            axios
              .delete(`/store/${storeId.value}/${device.ipadId}`)
              .then((res) => {
                resolve(res.data)
                router.push({
                  name: 'refresh'
                })
                notify(
                  {
                    classType: 'success',
                    message: '刪除成功',
                    closeAble: false
                  },
                  3000
                )
              })
              .catch((err) => {
                notify(
                  {
                    classType: 'error',
                    message: `刪除失敗 ${err.response.errors.detail}`,
                    closeAble: false
                  },
                  3000
                )
                reject(err)
              })
          })
        }
      })
      deleteDeviceModal.show()
    }

    function init() {
      Object.assign(
        selectEmails.value,
        allowEmails.value.map((item: any) => {
          return item.email
        })
      )
      axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `門市資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })

      axios
        .get(`/store/${storeId.value}`)
        .then((res) => {
          const data = res.data
          isEnable.value = data.isEnable
          storeEmail.value = data.storeEmail
          storeName.value = data.storeName
          Object.assign(
            selectEmails.value,
            [data.storeEmail].concat(selectEmails.value)
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `門市資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    init()

    return {
      storeName,
      storeEmail,
      isEnable,
      selectEmails,
      storeId,
      devices,
      deleteDeviceClick,
      save,
      cancel
    }
  }
})
