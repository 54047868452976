
import { defineComponent, computed, inject } from 'vue'
import { permissionInstance } from '@/type'
import helper from '@/helper'
export default defineComponent({
  props: [
    'propStoreName',
    'propStoreEmail',
    'propSelectEmails',
    'propIsEnable',
    'propStoreId'
  ],
  setup(props, { emit }) {
    const storeName = computed({
      get() {
        return props.propStoreName
      },
      set(val: any) {
        emit('update:propStoreName', val)
      }
    })
    const storeEmail = computed({
      get() {
        return props.propStoreEmail
      },
      set(val: any) {
        emit('update:propStoreEmail', val)
      }
    })
    const isEnable = computed({
      get() {
        return props.propIsEnable
      },
      set(val: any) {
        emit('update:propIsEnable', val)
      }
    })
    const storeId = computed({
      get() {
        return props.propStoreId
      },
      set(val: any) {
        emit('update:propStoreId', val)
      }
    })

    function isAdmin() {
      return helper.isAdmin()
    }

    return {
      storeName,
      storeEmail,
      isEnable,
      storeId,
      emit,
      isAdmin
    }
  }
})
