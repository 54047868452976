
import { computed, defineComponent, inject, reactive, watch } from 'vue'
import Fixed from '@/views/components/fixed.vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { notifyInstance } from '@/type'

interface group {
  name?: string
  id: string
}

export default defineComponent({
  emits: ['refresh'],
  props: ['propEmployees', 'propGroups', 'groupId'],
  components: {
    Fixed
  },
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()
    const group = reactive({
      name: '',
      id: ''
    } as group)

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    function init() {
      Object.assign(
        group,
        props.propGroups.find((propGroup: any) => {
          return parseInt(propGroup.id) === parseInt(props.groupId)
        })
      )
    }

    function save() {
      axios
        .patch(`/groups/${storeId.value}`, {
          groupName: group.name,
          groupId: group.id,
          type: 'groupName'
        })
        .then((res) => {
          // refresh sideBar
          emit('refresh')
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    function remove() {
      axios
        .delete(`/groups/${storeId.value}/${props.groupId}`)
        .then((res) => {
          emit('refresh')
          router.push({
            name: 'authPermission'
          })
          notify(
            {
              classType: 'success',
              message: '刪除成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `刪除失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    watch(props.propGroups, () => {
      init()
    })

    watch(props.propEmployees, () => {
      init()
    })

    init()

    return {
      group,
      save,
      cancel,
      remove
    }
  }
})
