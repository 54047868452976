
import { computed, defineComponent, inject, ref } from 'vue'
import axios from '@/http'
import { modalInstance, notifyInstance } from '@/type'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
export default defineComponent({
  emits: ['refresh'],
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const exportType = ref('Resigned')

    function exportExcelClick() {
      exportExcel(exportType.value, storeId.value, {}, modal, notify, router)
    }

    return {
      exportExcelClick
    }
  }
})
