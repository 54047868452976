
import { computed, defineComponent, inject, ref } from 'vue'
import Fixed from '@/views/components/fixed.vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { notifyInstance } from '@/type'

export default defineComponent({
  emits: ['refresh'],
  props: ['propEmployees', 'propGroups'],
  components: {
    Fixed
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()
    const groupName = ref('')

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    function save() {
      axios
        .post(`/groups/${storeId.value}`, { groupName: groupName.value })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      groupName.value = ''
    }

    return {
      groupName,
      save,
      cancel
    }
  }
})
