
import { defineComponent, computed } from 'vue'
import { employee, group } from '@/type'
import { useRoute } from 'vue-router'
import { prop } from 'vue-class-component'

export default defineComponent({
  props: ['propEmployees', 'propGroups'],
  setup(props, { emit }) {
    const route = useRoute()

    const employees = computed({
      get() {
        return props.propEmployees
      },
      set(val: any) {
        emit('update:propEmployees', val)
      }
    })

    const groups = computed({
      get() {
        return props.propGroups
      },
      set(val: any) {
        emit('update:propGroups', val)
      }
    })

    function validEmployees(group: any) {
      return employees.value.filter((employee: employee) => {
        return employee.status && group.employeeIds.indexOf(employee.id) !== -1
      })
    }

    function noGroupEmployees(groups: any) {
      return employees.value.filter((employee: employee) => {
        return (
          employee.status &&
          groups.every((group: group) => {
            return group.employeeIds.indexOf(employee.id) === -1
          })
        )
      })
    }

    function employeeStartDrag(event: any, group: group, employee: employee) {
      event.dataTransfer.setData('dragFrom', group ? group.id : 'null')
      event.dataTransfer.setData('dragEmployee', parseInt(employee.id))
    }

    function employeeOnDrop(event: any, list: any) {
      let dragFrom = event.dataTransfer.getData('dragFrom')
      let dragEmployee = event.dataTransfer.getData('dragEmployee')

      if (dragFrom !== 'null') {
        dragFrom = parseInt(dragFrom)
        const originGroupIndex = groups.value.findIndex(
          (group: group) => group.id === dragFrom
        )
        dragEmployee = parseInt(dragEmployee)
        groups.value[originGroupIndex].employeeIds = groups.value[
          originGroupIndex
        ].employeeIds.filter(function (item: any) {
          return item !== dragEmployee
        })
      }
      if (list !== 'null') {
        const toGroupIndex = groups.value.findIndex(
          (group: any) => group === list
        )
        groups.value[toGroupIndex].employeeIds.push(dragEmployee)
      }

      emit('dropDone')
    }

    return {
      route,
      employees,
      groups,
      validEmployees,
      noGroupEmployees,
      employeeStartDrag,
      employeeOnDrop
    }
  }
})
