
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { employee, group } from '@/type'

export default defineComponent({
  props: ['propEmployees', 'propAllowUsers', 'propGroups', 'propAclItem'],
  setup(props, { emit }) {
    const noGroup = reactive({
      show: false
    })
    const groupShow = reactive([] as any)
    const groupsCheck = ref([])

    const setRef = (el: never) => {
      groupsCheck.value.push(el)
    }

    const groups = computed(() => props.propGroups)
    const employees = computed(() => props.propEmployees)
    const aclItem = computed(() => props.propAclItem)
    const allowUsers = computed({
      set(val: any) {
        emit('update:propAllowUsers', val)
      },
      get() {
        return props.propAllowUsers
      }
    })

    onMounted(() => {
      groups.value.forEach((group: any, groupIndex: any) => {
        const result = validEmployees(group).some((employee: any) => {
          return allowUsers.value.indexOf(employee.id) !== -1
        })
        groupShow[groupIndex] = result
      })

      const result = noGroupEmployees(groups.value).some((employee: any) => {
        return allowUsers.value.indexOf(employee.id) !== -1
      })
      noGroup.show = result
    })

    function validEmployees(group: group) {
      return employees.value.filter((employee: any) => {
        return employee.status && group.employeeIds.indexOf(employee.id) !== -1
      })
    }

    function noGroupEmployees(groups: any) {
      return employees.value.filter((employee: employee) => {
        return (
          employee.status &&
          groups.every((group: any) => {
            return group.employeeIds.indexOf(employee.id) === -1
          })
        )
      })
    }

    function checkGroup(group: group, checked: boolean) {
      validEmployees(group).forEach((employee: any) => {
        if (checked) {
          if (allowUsers.value.indexOf(employee.id) === -1) {
            allowUsers.value.push(employee.id)
          }
        } else {
          if (allowUsers.value.indexOf(employee.id) !== -1) {
            allowUsers.value.splice(allowUsers.value.indexOf(employee.id), 1)
          }
        }
      })
    }

    function checkNoGroup(groups: any, checked: boolean) {
      noGroupEmployees(groups).forEach((employee: employee) => {
        if (checked) {
          if (allowUsers.value.indexOf(employee.id) === -1) {
            allowUsers.value.push(employee.id)
          }
        } else {
          if (allowUsers.value.indexOf(employee.id) !== -1) {
            allowUsers.value.splice(allowUsers.value.indexOf(employee.id), 1)
          }
        }
      })
    }

    function checkAllGroup(check: boolean) {
      groupsCheck.value.forEach((el: any) => {
        el.checked = check
      })
      checkNoGroup(groups.value, check)
      groups.value.forEach((group: group) => {
        checkGroup(group, check)
      })
    }

    return {
      noGroup,
      groupShow,
      groups,
      employees,
      aclItem,
      allowUsers,
      validEmployees,
      noGroupEmployees,
      checkGroup,
      checkNoGroup,
      checkAllGroup,
      setRef
    }
  }
})
